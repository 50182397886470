import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children, permissionArray }) => {
  const { authToken, authUserPermission} = useAuth();
  const location = useLocation();
  const [status, setStatus] = useState('checking'); // 'checking', 'authorized', 'unauthorized'

  useEffect(() => {
    const validateToken = async () => {
      if (!authToken) {
        // console.log('token is not present')
        setStatus('unauthorized');
        return;
      }
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/public/addresstypes?is_trashed=0`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (response.ok) {
          // console.log('token is present and authorized')
          setStatus('authorized');
        }else{
          // If the token is not valid or expired
          // console.log('token is present and unauthorized')
          setStatus('unauthorized');
        }
      } catch (error) {
        console.error('Error while validating token:', error);
        setStatus('unauthorized');
      }
    };
    console.log('privateRoute...........');
    validateToken();
  }, []); // Effect runs when authToken changes

  if (status === 'checking') {
    // You can replace this with a spinner or a loader component
    return <div>Loading...</div>;
  }


  if (status === 'unauthorized') {
    // Redirect to the login page if unauthorized
    return <Navigate to="/auth/loginformik" state={{ from: location }} replace />;
  }

  if(permissionArray?.[0] === 'home'){
    return children;
  }

  if(authUserPermission?.[permissionArray?.[0]]?.[permissionArray?.[1]] !== '1'){
    // console.log('permissionArray',permissionArray);
    // console.log('authUserPermission',authUserPermission);
    const [module, action] = permissionArray; 
    const hasPermission = authUserPermission?.[module]?.[action] === "1"; 
  
    console.log(`Checking permission for ${module} -> ${action}:`, hasPermission);
    return <Navigate to="/auth/not-authorised"/>;
   }
   
    // If authorized, render the children
    return children; 
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  permissionArray: PropTypes.array.isRequired
};

export default PrivateRoute;
