// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(localStorage.getItem('userToken'));
  const [authUser, setAuthUser] = useState(localStorage.getItem('user'));
  const [authUserPermission, setAuthUserPermission] = useState(JSON.parse(localStorage.getItem("permissions")) || {});

  
  const login = (token,email,permissions) => {
    localStorage.setItem('userToken', token);
    localStorage.setItem('user', email);
    localStorage.setItem('permissions', JSON.stringify(permissions));
    setAuthToken(token);
    setAuthUser(email);
    setAuthUserPermission(permissions);
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    setAuthToken(null);
    setAuthUser(null);
    setAuthUserPermission(null);
    navigate('auth/loginformik')
  };
  
  return (
    <AuthContext.Provider value={{ authToken,authUser,authUserPermission, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};